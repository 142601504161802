<template>
  <div>
    <b-row class="match-height">
      <b-col
        cols="12"
        xl="12"
      >
        <b-card no-body>
          <b-card-body>
            <div class="remote-top">
              <b-card-title class="text-remote mb-0">Bancada 3 refeitório fábrica</b-card-title>
            </div>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>

    <b-row class="device-remote2">
      <b-col
        cols="12"
        xl="4"
      >
        <b-card no-body>
          <b-card-body>
            <div class="remote2-img">
              <img src="https://fakeimg.pl/525x251/" alt="">
            </div>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col
        class="align-self-center mb-1"
        cols="12"
        xl="8"
      >
          <b-row class="device-status">
            <b-col class="device-column-btn" cols="6" xl="3">
              <b-button
                class="devices-btn active"
                variant="none"
              >
                <img src="@/assets/images/icons/off.png" alt="">
                <span>Ligar</span>
              </b-button>
            </b-col>
            <b-col class="device-column-btn" cols="6" xl="3">
              <b-button
                class="devices-btn"
                variant="none"
              >
                <img src="@/assets/images/icons/descongelacao.png" alt="">
                <span>Descongelação</span>
              </b-button>
            </b-col>
            <b-col class="device-column-btn" cols="6" xl="3">
              <b-button
                class="devices-btn active"
                variant="none"
              >
                <img src="@/assets/images/icons/light.png" alt="">
                <span>Ligar iluminação</span>
              </b-button>
            </b-col>
            <b-col class="device-column-btn" cols="6" xl="3">
              <b-button
                class="devices-btn"
                variant="none"
              >
                <img src="@/assets/images/icons/lock.png" alt="">
                <span>Desbloquear</span>
              </b-button>
            </b-col>
          </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard, BCardText, BButton, BRow, BCol, BImg, 
  BCardBody, BCardTitle, BCardSubTitle, BLink, VBTooltip
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCard,
    BCardText,
    BButton,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BRow,
    BCol,
    BLink,
    BImg,
    VBTooltip,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
}
</script>