<template>
  <b-card no-body>
    <!-- apex chart -->
    <b-row class="program-temp">
        <!-- apex chart -->
        <b-col
        cols="12"
        xl="3"
        class="program-temperature"
        >
            <span class="text-temp">
                Min. Temp.
            </span>
            <span class="temp-number">
                {{ goal_overview.min_temp }}
            </span>
        </b-col>
        <b-col cols="12" xl="6">
            <vue-apex-charts
              type="radialBar"
              height="250"
              :options="goalOverviewRadialBar.chartOptions"
              :series="goalOverviewRadialBar.series"
            />
            <div class="section-btn-temp">
                <b-button
                  class="btn-temp"
                  variant="none"
                >
                  <feather-icon
                    class="icon-temp"
                    icon="MinusCircleIcon"
                  />
                </b-button>
                <b-button
                  class="btn-temp"
                  variant="none"
                >
                  <feather-icon
                    class="icon-temp"
                    icon="PlusCircleIcon"
                  />
                </b-button>
            </div>
        </b-col>
        <b-col
        cols="12"
        xl="3"
        class="program-temperature mobile"
        >
            <span class="text-temp">
                Máx. Temp.
            </span>
            <span class="temp-number">
                {{ goal_overview.max_temp }}
            </span>
        </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BRow, BCol, BCardText,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'

export default {
  components: {
    VueApexCharts,
    BCard,
    BCardHeader,
    BRow,
    BCardText,
    BCol,
  },
  data() {
    return {
      goal_overview: {
        min_temp: '0,0°',
        max_temp: '3,3°',
      },
      goalOverviewRadialBar: {
        series: [83],
        chartOptions: {
          chart: {
            sparkline: {
              enabled: true,
            },
            dropShadow: {
              enabled: true,
              blur: 3,
              left: 1,
              top: 1,
              opacity: 0.1,
            },
          },
          colors: ['#51e5a8'],
          plotOptions: {
            radialBar: {
              offsetY: -10,
              startAngle: -150,
              endAngle: 150,
              hollow: {
                size: '80%',
              },
              track: {
                background: '#ebe9f1',
                strokeWidth: '100%',
              },
              dataLabels: {
                name: {
                  offsetY: 30,
                  color: '#5e5873',
                  fontSize: '18px',
                },
                value: {
                  offsetY: -15,
                  color: '#5e5873',
                  fontSize: '40px',
                },
              
              },
            },
          },
          fill: {
            type: 'gradient',
            colors: '#28c76f'
          },
          stroke: {
            lineCap: 'round',
          },
          grid: {
            padding: {
              bottom: 30,
            },
          },
          labels: ['Set Point'],
        },
      },
    }
  },
}
</script>