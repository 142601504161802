<template>
  <b-card-code>
    <swiper
      class="swiper-responsive-breakpoints"
      :options="swiperOptions"
      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
    >
      <swiper-slide
        v-for="(data,index) in swiperData"
        :key="index"
      >
        <b-img
          class="carousel-img"
          :src="data.img"
          fluid
        />
        <span class="carousel-text">{{ data.text }}</span>
      </swiper-slide>

      <div
        slot="pagination"
        class="swiper-pagination"
      />
    </swiper>
    <b-button
        class="new-program-btn"
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        v-b-modal.modal-center
        variant="none"
    >
        <span>Criar novo programa</span>
    </b-button>
    <b-modal
      id="modal-center"
      centered
      ok-only
      ok-title="Guardar"
    >
      <span class="modal-title">Nova Receita 001</span>
      <b-form-group>
        <v-select
          class="modal-select"
          id=""
          v-model="selectedProgram"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :selectable="option => ! option.value.includes('select_currency')"
          :options="programName"
          label="text"
        />
      </b-form-group>
      <b-row>
        <b-col cols="4">
          <b-form-group
            label="Temp. mínima"
          >
            <b-form-input
              id="basicInput"
              placeholder="0000"
            />
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-form-group
            label="Temp. máxima"
          >
            <b-form-input
              id="basicInput"
              placeholder="0000"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-modal>
    <template #code>
      {{ codeResponsive }}
    </template>
  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import { BRow, BCol, BImg, BButton, BFormGroup, BFormInput, VBModal, BModal, } from 'bootstrap-vue'
import 'swiper/css/swiper.css'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { codeResponsive } from './code'

export default {
  components: {
    BRow,
    BCol,
    Swiper,
    SwiperSlide,
    BImg,
    BButton,
    BFormGroup, 
    BFormInput, 
    BModal,
    BCardCode,
    vSelect
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      codeResponsive,
      selectedProgram: 'Nova Receita',
      programName: [
        // { value: 'select_currency', text: 'Select Currency' },
        { value: 'PADARIA', text: 'Padaria e Pastelaria' },
        { value: 'PEIXE', text: 'Peixe' },
        { value: 'GASTRONOMIA', text: 'Gastronomia' },
        { value: 'BEBIDAS', text: 'Bebidas' },
      ],
      /* eslint-disable global-require */
      swiperData: [
        { img: require('@/assets/images/banner/banner-30.jpg'), text: 'Pizza' },
        { img: require('@/assets/images/banner/banner-31.jpg'), text: 'Legumes' },
        { img: require('@/assets/images/banner/banner-32.jpg'), text: 'Queijos' },
        { img: require('@/assets/images/banner/banner-33.jpg'), text: 'Carne vermelha' },
        { img: require('@/assets/images/banner/banner-34.jpg'), text: 'Polvo' },
        { img: require('@/assets/images/banner/banner-35.jpg'), text: 'Frutas' },
      ],
      /* eslint-disable global-require */

      swiperOptions: {
        slidesPerView: 6,
        spaceBetween: 50,
        loop: true,
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
        breakpoints: {
          1024: {
            slidesPerView: 6,
            spaceBetween: 10,
          },
          992: {
            slidesPerView: 3,
            spaceBetween: 10,
          },
          640: {
            slidesPerView: 2,
            spaceBetween: 10,
          },
          320: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
        },
      },
    }
  },
}
</script>