<template>
  <div>
    <b-row class="match-height">
      <b-col
        cols="12"
        xl="12"
      >
        <b-card no-body class="remote-listagem">
          <b-card-body>
            <div class="listagem-top">
              <span class="text-listagem">
                  Programas
              </span>
            </div>
          </b-card-body>
        </b-card>
        <b-card no-body>
          <b-card-body>
            <b-col cols="12" xl="8" offset-xl="2">
              <remote-programas-temp />
            </b-col>
            <b-col cols="12" xl="12">
              <remote-food-carousel />
            </b-col>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard, BCardText, BButton, BRow, BCol, BCardBody,
  BFormGroup, BFormInput, BInputGroup, BInputGroupPrepend
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import RemoteFoodCarousel from './RemoteFoodCarousel.vue'
import RemoteProgramasTemp from './RemoteProgramasTemp.vue'

export default {
  components: {
    BCard,
    BCardText,
    BButton,
    BCardBody,
    BRow,
    BCol,
    BFormGroup, 
    BFormInput, 
    BInputGroup, 
    BInputGroupPrepend,
    vSelect,
    RemoteFoodCarousel,
    RemoteProgramasTemp
  },
  directives: {
    Ripple,
  },
  data() {
    return {
       searchTerm: '',
    }
  }
}
</script>