<template>
  <section id="dashboard">
    <b-row class="match-height">
      <b-col
        lg="12"
        sm="12"
      >
        <remote-devices />
      </b-col>
    </b-row>

    <b-row class="match-height">
      <b-col
        lg="12"
        sm="12"
      >
        <remote-programas />
      </b-col>
    </b-row>
  </section>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import RemoteDevices from '../Remote-2/RemoteDevices.vue'
import RemoteProgramas from './RemoteProgramas.vue'

export default {
  components: {
    BRow,
    BCol,
    RemoteDevices,
    RemoteProgramas,
  },
  data() {
    return {
      data: {},
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-remote.scss';
</style>
